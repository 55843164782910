import { Component, OnInit } from '@angular/core';
import { GraphReports } from 'src/app/Graph/graph-reports';
import { Formdata } from 'src/app/localStorage/formdata';
import { Router } from '@angular/router';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-saving-summary',
  templateUrl: './saving-summary.component.html',
  styleUrls: ['./saving-summary.component.css']
})
export class SavingSummaryComponent implements OnInit {
  modelOps:any;
  graphData:any;
  setGraphdatas:any
  getFormData:any;
  calculatedArray:any
  projDetails:any
  projectName:any;
  getMNVopt:any
  getBuildata:any
  modelType:any
  constructor(private GraphReport:GraphReports,private formDatas:Formdata,
    private router: Router,private dataSharing:DatashareService) { }

  ngOnInit() {

    //--------------Project Details--------------//
    this.projDetails = JSON.parse(this.formDatas.getProjectReg());
    this.projectName = this.projDetails[0].project_name;

    this.getMNVopt = this.formDatas.getLocalStoragMNVopt();
    this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
    this.modelType =  this.formDatas.getModelName(); 
      //--------------Project Details--------------// 

    this.getChartdata();
    
    this.getLocalStorage()
   }

  getChartdata(){
    console.log("heloo")
    let getFormsData;
    this.modelOps = this.formDatas.getModelOutput();
    this.getFormData = this.formDatas.gtLocalStoragBuildMNV();
    this.graphData =  JSON.parse( this.modelOps);
    getFormsData =  JSON.parse( this.getFormData);
    this.calculatedArray =   JSON.parse(this.formDatas.getNRAoutput());
    //console.log(this.calculatedArray);
    console.log(this.graphData);
    this.setGraphdata(this.graphData,getFormsData,this.calculatedArray);
  }
  setGraphdata(data,getFormsData,savingData){ 
    let nameArr = [];
   if(getFormsData.model_name=="ACS"){
        nameArr = ["Adj Baseline Consumption","Baseline Consumption","Reporting Consumption"];
        this.setGraphdatas =  JSON.parse(data.data.data);
        
        this.GraphReport.consumptionDatas(this.setGraphdatas,"lineChart1",nameArr,"adj_baseline_consumption")
        console.log('hello123')
       }
      else{
        nameArr = ["Adj Reporting Consumption","Baseline Consumption","Reporting Consumption"];
        this.setGraphdatas =  JSON.parse(data.data.data);
        console.log(this.setGraphdatas)
        this.GraphReport.consumptionDatas(this.setGraphdatas,"lineChart1",nameArr,"adj_reporting_consumption")
         console.log('hello123')
       }
         console.log('hello1235',savingData)
         this.GraphReport.savingDatas(savingData,"lineChart2")
  }

  getLocalStorage(){
      let nraData;
      nraData =  JSON.parse(this.formDatas.getNRAoutput());
      console.log(nraData);
      this.dataCalculations(nraData);
  }
  //////Calculation Begins Here//////////
  dataCalculations(data){
      
  }
  onSubmit(){
    this.router.navigateByUrl('/mnv-forms/mnv-report');
  }

}
