import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/AuthServices/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'MNV-app';
  loggedIn:any;
  constructor(private router:Router,private auth:AuthService){}
  ngOnInit() {
    this.auth.authStatus.subscribe(value => this.loggedIn = value)
   console.log("hello")
 if(this.loggedIn){
    console.log(this.loggedIn)
   this.router.navigateByUrl('/dashboard');
  }
  else{
   this.router.navigateByUrl('/login');
  }
  }
}
