import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkService } from 'src/app/services/AuthServices/network.service';
//import { SnotifyService  } from 'ng-snotify';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {
  public error:any ;
  public form = {
    email : null,
    password : null,
    password_confirmation: null,
    resetToken : null
  }
  constructor(
    private route:ActivatedRoute,
    private network:NetworkService,
    private router:Router,
   
    ) {
    route.queryParams.subscribe(params =>{
      this.form.resetToken = params['token']
    });

   }

  ngOnInit() {
  }

  onSubmit(){
    this.network.changePassword(this.form).subscribe(
      data=> this.handleResponse(data),
      error=> console.error(error)
    );

    }
    handleResponse(data){
        console.log(data)
    }
  /*  handleResponse(data){
      let _router = this.router
      this.notify.confirm('Example body content',{
        buttons: [
          {
            text: 'ok',
            action: toastor => {
              _router.navigateByUrl('/login'),
              this.notify.remove(toastor.id)
            }
          }
        ]
      });
    }
    handleError(error){
      let _router = this.router
      if(error.error.errors){
        this.error = error.error.errors;
      }
      else{
        this.notify.error('Example body content',{
          buttons: [
            {
              text: 'ok',
              action: toastor => {
                _router.navigateByUrl('/login'),
                this.notify.remove(toastor.id)
              }
            }
          ]
        });
      }
    }*/


}
