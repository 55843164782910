import { Injectable } from '@angular/core';

@Injectable({providedIn:'root'})
export class Formdata {
    data:string;
    ModelOp:string;
    model:string;
   
    setProjectReg(data){
            localStorage.setItem('projectName', data);
        }
    getProjectReg(){
            this.data = localStorage.getItem('projectName');
            return this.data;
        }


    //-----### FORM - 1  ###------// 
    setLocalStoragRegProj(data){
        localStorage.setItem('regProject', data);
    }
    getLocalStoragRegProj(){
        this.data = localStorage.getItem('regProject');
        console.log(this.data);
        return this.data
    }
    //-----### FORM - 1  ###------// 
    
    //-----### FORM - 2  ###------// 
    setLocalStoragECMdetails(data){
        localStorage.setItem('ECMdetails', data);
    }
    getLocalStoragECMdetails(){
        this.data = localStorage.getItem('ECMdetails');
        console.log(this.data);
        return this.data;
    }
    //-----### FORM - 2  ###------// 
    
     //-----### FORM - 3  ###------// 
     setLocalStoragMNVopt(data){
        localStorage.setItem('MNVopt', data);
    }
    getLocalStoragMNVopt(){
        this.data = localStorage.getItem('MNVopt');
        return this.data;
    }
     //-----### FORM - 3  ###------// 

     //-----### FORM - 4  ###------// 
     setLocalStoragBuildMNV(data){
        localStorage.setItem('BuildMNV', data);
     }
     gtLocalStoragBuildMNV(){
        this.data = localStorage.getItem('BuildMNV');
        return this.data;
     }
     //-----### FORM - 4  ###------// 

     //-----### FORM - 5  ###------// 
     setLocalStoragIP1(data){
        localStorage.setItem('IP1', data);
     }
     getLocalStoragIP1(){
        this.data = localStorage.getItem('IP1');
        return this.data;
     }
    //-----### FORM - 5  ###------// 

     //-----### FORM - 6  ###------// 
     setLocalStoragIP2(data){
        localStorage.setItem('IP2', data);
     }
     getLocalStoragIP2  (){
        this.data = localStorage.getItem('IP2');
        return this.data;
     }
    //-----### FORM - 6  ###------// 


    setNRAoutput(data){
       localStorage.setItem('NRA',data);
      
    }
    getNRAoutput(){
        this.data = localStorage.getItem('NRA');
        return this.data;
    }
    setNRAdata(data){
        localStorage.setItem('NRAdata',data);
       
     }
     getNRAdata(){
        this.data = localStorage.getItem('NRAdata');
        console.log(this.data );
        return this.data;
    }
    
    setModelOutput(data){
        localStorage.setItem('modelOutput', data);
     }
     getModelOutput(){
        this.data = localStorage.getItem('modelOutput');
       return this.data;
     }

     setNSdata(data){
        localStorage.setItem('NS', data);
     }
     getNSdata(){
        this.data = localStorage.getItem('NS');
       return JSON.parse(this.data);
     }
     setACSdata(data){
        localStorage.setItem('ACS', data);
     }
     getACSdata(){
        this.data = localStorage.getItem('ACS');
        
        return JSON.parse(this.data);
     }
   setModelFor1var(data){
      localStorage.setItem('singleVar', data);
   }
   getsetModelFor1var(){
      this.data = localStorage.getItem('singleVar');
      console.log(JSON.parse(this.data))
      return JSON.parse(this.data);
   }
   setBt1(data){
      localStorage.setItem('BT1', data);
   }
   getBt1(){
      this.data = localStorage.getItem('BT1');
       return JSON.parse(this.data);
   }
   setBt2(data){
      localStorage.setItem('BT2', data);
   }
   getBt2(){
      this.data = localStorage.getItem('BT2');
       return JSON.parse(this.data);
   }
   setModelName(data){
      console.log(data)
      this.model = data
   }
   getModelName(){
      return this.model;
   }
}
