import { Injectable } from '@angular/core';
import { GetAllAlertsService } from './get-all-alerts.service';
import { Formdata } from 'src/app/localStorage/formdata';

@Injectable({
  providedIn: 'root'
})
export class CustomFormValidationsService {
  DataRow:any;
  DataRows = [];
  lenghtError = "Please Check Lenght of Data"
  monthDataError = "Please Enter Month Data i.e 12 Rows Each"
  dateFormatError = "Please Enter Date in 01-01-2020 or 01/01/2020"
  dataAnyError = "Data Error Please Check Data!!"
  dataNumeric = "Data should be numeric"
  dataSuccess = "Data saved Successfully"
  getBuildata:any;
  getDataLenght = [];
  constructor(private alertError:GetAllAlertsService,private formDatas:Formdata) { }
validateData(data,modelId){
  this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
  this.DataRows = []
  this.getDataLenght = []
  let modelType = modelId
  console.log(data)
  for(var key in data)
  {
      if(key=='Timestamp'){
        this.DataRow =  this.removeSpaceFromDateFormat(data[key]);
        data.Timestamp=this.DataRow;
        if(this.getBuildata.model_type==2){
        this.getDataLenght.push({"Timestamp":this.DataRow.length,
                                 "dependent_variable":"0",
                                 "independent_variable":"0" })
        }else{
          this.getDataLenght.push({"Timestamp":this.DataRow.length,
                                   "dependent_variable":"0" })
        }
      }
      if(key=='dependent_variable'){
        this.DataRow =  this.removeSpaceFromData(data[key],key)
          data.dependent_variable=this.DataRow;
          this.getDataLenght[0].dependent_variable = this.DataRow.length
      }
     if(key=='independent_variable2'){
        this.DataRow =  this.removeSpaceFromData(data[key],key)
        data.independent_variable2=this.DataRow;
        this.getDataLenght[0].independent_variable = this.DataRow.length
      }
  }
  if(this.checkMonthLength(this.getDataLenght[0])){
  if(this.checkLenght(this.getDataLenght[0])){
    
      return this.setDataResult(modelType,data)
    }
}
 
  //this.alertError.showAlert(this.dataAnyError)
  return false
 
  
}

setDataResult(modelType,data){
  console.log(modelType)
  let DataResult = []
 for(var key in data.Timestamp){
      if(modelType==1){
       DataResult.push({'Timestamp':data.Timestamp[key],'dependent_variable':data.dependent_variable[key]})
      }
       else{
        DataResult.push({'Timestamp':data.Timestamp[key],'dependent_variable':data.dependent_variable[key],'independent_variable2':data.independent_variable2[key]})
       }
    }
    this.alertError.showAlertSuccess(this.dataSuccess)
    return DataResult;
}

removeSpaceFromDateFormat(keyData){
if(!Array.isArray(keyData)){
    console.log(keyData)
   //----Remove New Line and Space-----//
  this.DataRow = keyData.split("\n");
  this.DataRow = this.DataRow.filter(Boolean);
    //----*Remove New Line and Space*-----//
   
    //----FORMAT DATE DD-MM-YYYY-----//
  for(var date  in  this.DataRow){
       this.DataRows.push(this.formatDate(this.DataRow[date]));
   }
      //----*FORMAT DATE DD-MM-YYYY*-----//
 
      //----CHECK DATE FORMAT--------//
  for(var date  in  this.DataRows){
       if(!this.checkDateFormat(this.DataRows[date])){
        return false;
       } 
      }
       //----*CHECK DATE FORMAT*-----//
       return  this.DataRows;
  } 
 
  else{
    return  keyData;
  } 
  

  
 
}
removeSpaceFromData(keyData,key){
  console.log(keyData)
  let flag;
  let removeComma
  if(!Array.isArray(keyData)  ){
      //----Remove New Line and Space-----//
  removeComma =  keyData.replace(/\,/g,"");
  this.DataRow = removeComma.split("\n");
  this.DataRow = this.DataRow.filter(Boolean);
  //----Remove New Line and Space-----//
  console.log(this.DataRow)
   return this.DataRow;
  }
   else{
        return keyData;
   }
   
}


formatDate(date) 
{
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year,  day ,month ].join('-');
}
checkDateFormat(date:string){
  if(date.match(/^[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/) || date.match(/^[0-9]{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])/))
      {
        console.log("--------------")
        return true;
      }
      else {
        this.alertError.showAlert(this.dateFormatError)
        return false ;
      }
    }
checkLenght(data){
console.log(data)
  if(Object.keys(data).length==3){
  if(data.Timestamp==data.dependent_variable){
          if(data.Timestamp==data.independent_variable)
            {
               if(data.independent_variable==data.dependent_variable)
                {
                   return true;
                }
                else{
                  console.log("3")
                  this.alertError.showAlert(this.lenghtError)
                  return false
                }
            }
            else
              {
                console.log("2")
               this.alertError.showAlert(this.lenghtError)
               return false
              }
       }else{
         console.log("1")
        this.alertError.showAlert(this.lenghtError)
        return false
      }
    }
    else{
      if(data.Timestamp==data.dependent_variable){
         return true;
      }else{
        console.log("4")
        this.alertError.showAlert(this.lenghtError)
        return false;
      }
      
    }
  }
checkMonthLength(data)  {
  console.log(data)
  if(Object.keys(data).length==3){
   if(data.Timestamp==12 && data.dependent_variable==12  && data.independent_variable==12 ){
        return true;
    }else{
        this.alertError.showAlert(this.monthDataError)  
        return false;
    }
  }else{
    if(data.Timestamp==12 && data.dependent_variable==12)
    {
         return true;
    }
    else
    {
         this.alertError.showAlert(this.monthDataError)  
         return false;
    }
    
  }
}
checkNumber(data){
  console.log(data)
  for(var val in data){
    if(!parseInt(data[val]))
    {
      console.log("checkNumber")
      return false
    }
  }
  return data
} 
    
}

