import { Component, OnInit } from '@angular/core';

//import { HttpClient } from '@angular/common/http';
//import { error } from '@angular/compiler/src/util';
import { NetworkService } from 'src/app/services/AuthServices/network.service';
import { TokenService } from 'src/app/services/AuthServices/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/AuthServices/auth.service';
import { FormGroup,FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})




export class LoginUserComponent implements OnInit , ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
 

  constructor(
    private networkCall:NetworkService,
    private token:TokenService,
    private router:Router,
    private auth:AuthService,private formBuilder: FormBuilder) {  console.log('hello')}
  
    myForm:FormGroup;
    private loggedIn:any;
    public error = null;
    public form ={
    user_name:null,
    password:null
  }
  

  
  ngOnInit() {
    console.log('hello')
    this.auth.authStatus.subscribe(value => this.loggedIn = value)
    console.log(this.loggedIn)
    if(this.loggedIn){
       console.log(this.loggedIn)
       this.router.navigateByUrl('/dashboard');
     }
     this.myForm =  this.formBuilder.group({
      'user_name':['', Validators.required],
      'password':['', Validators.required],
      

     });
    
  }

  onSubmit(data){
   // console.log(this.form);
   return this.networkCall.login(data).subscribe(
      data=> this.handleResponse(data),
      error=>  this.handleError(error)
    );
    //console.log(resp);
  }

  handleResponse(data){
    this.token.handle(data.access_token);
    this.auth.changeAuthStatus(true);
    this.router.navigateByUrl('/dashboard');
  }

  handleError(error){
    this.error = error.error.error;   
  }

}




