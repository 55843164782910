import { Component, OnInit } from '@angular/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { GraphReports } from 'src/app/Graph/graph-reports';
import { ProjectDetailsService } from 'src/app/services/project-details.service';
import { CustomFormValidationsService } from 'src/app/services/Alerts/custom-form-validations.service';
import { DatashareService } from 'src/app/services/modal/datashare.service';


@Component({
  selector: 'app-mnv-report',
  templateUrl: './mnv-report.component.html',
  styleUrls: ['./mnv-report.component.css']
})
export class MnvReportComponent implements OnInit {
  getNSdata:any;
  constructor(private getNsBsdata:Formdata,private GraphReport:GraphReports,
    private messageService: ProjectDetailsService,private dataSharing:DatashareService) { 
  
  }
data = []
grossVals = [];
RoutineAdjustments = [];
calculateCDDandOCC=[];
nameArr = []
graphData:any;
modelOps:any;
setGraphdatas:any
formInfo:any;
ProjectName:any
anuualBill:any
typeBuilding:any
energyType:any
orgName:any
mnvOpt:any
messages:any
subscription:any;
getBuildata:any;
modelType:any
frequency_of_data:any
modelOutputres:any;
bstCDD:any;
  ngOnInit() {
    let tmpGrossVals:any;
    let tmpRoutineAdjustments:any;
    let tmpcalculateCDDandOCC:any;
    let nraData:any;
   
    this.getNSdata = this.getNsBsdata.getNSdata();
    this.modelOps = this.getNsBsdata.getModelOutput();
    this.getBuildata = JSON.parse(this.getNsBsdata.gtLocalStoragBuildMNV());
    if(this.getBuildata.model_name=="ACS"){
      this.getNSdata =  this.getNsBsdata.getACSdata();
    }else{
      this.getNSdata =  this.getNsBsdata.getNSdata();
    }
    this.modelType = this.getBuildata.model_name; 
    this.frequency_of_data = this.getBuildata.frequency_of_data

   
   
   
   
    this.graphData =  JSON.parse( this.modelOps);
    this.formInfo = JSON.parse(this.getNsBsdata.getLocalStoragRegProj());
    let ProjectNames = JSON.parse(this.getNsBsdata.getProjectReg());
    this.formInfo = JSON.parse(this.getNsBsdata.getLocalStoragRegProj());
    
    nraData =  JSON.parse(this.getNsBsdata.getNRAdata())
    
    tmpGrossVals = this.getNSdata[0].grossVals
    tmpRoutineAdjustments = this.getNSdata[1].RoutineAdjustments
    tmpcalculateCDDandOCC = this.getNSdata[2].calculateCDDandOCC
    this.grossVals = this.getNSdata[1].RoutineAdjustments;
    
   
    this.ProjectName = ProjectNames[0].project_name
    this.orgName = this.formInfo.organization_name;
    this.anuualBill = this.formInfo.annual_bill;
    this.typeBuilding = this.formInfo.type_of_building;
    this.energyType = this.formInfo.energy_type;
    this.mnvOpt = "C";
    
   this.modelOutputres = JSON.parse(this.graphData.data.data)
   
  if(this.getBuildata.model_type=="2"){
    this.setBestBtVal(this.graphData);
   }else{
    this.bstCDD =this.getNsBsdata.getBt1();
   }
    
    console.log(this.getNSdata);
    for(var j = 0; j<12;j++){
          this.data.push({
             "Timestamp":this.getNSdata[0]['grossVals'][j].Timestamp,
              "TempGrosVals":this.getNSdata[0]['grossVals'][j].TempGrosVals,
              "TempPerGrosVals":this.getNSdata[0]['grossVals'][j].TempPerGrosVals,
              "tmpdataBaseline":this.getNSdata[0]['grossVals'][j].tmpdataBaseline,
              "tmpdataReporting":this.getNSdata[0]['grossVals'][j].tmpdataReporting,
             
              "RoutineAdjs":this.getNSdata[1]['RoutineAdjustments'][j].RoutineAdjs,
              "RoutineAdjCostSavings":this.getNSdata[1]['RoutineAdjustments'][j].RoutineAdjCostSavings,
              "NonRoutineAdjCostSaving":this.getNSdata[1]['RoutineAdjustments'][j].NonRoutineAdjCostSaving,
              "NonRoutineAdj":this.getNSdata[1]['RoutineAdjustments'][j].NonRoutineAdj,
              "TotalAdjustments":this.getNSdata[1]['RoutineAdjustments'][j].TotalAdjustments,
              "TemPerSavings":this.getNSdata[1]['RoutineAdjustments'][j].TemPerSavings,
              "TempnetSavings":this.getNSdata[1]['RoutineAdjustments'][j].TempnetSavings,
              
              "ChangeInCdd":this.getNSdata[2]['calculateCDDandOCC'][j].ChangeInCdd,
              "ChangeInOCC":this.getNSdata[2]['calculateCDDandOCC'][j].ChangeInOCC,
              "Baseline_cdd":this.getNSdata[2]['calculateCDDandOCC'][j].Baseline_cdd,
              "Reporting_cdd":this.getNSdata[2]['calculateCDDandOCC'][j].Reporting_cdd,
              "baseline_occupancy":this.getNSdata[2]['calculateCDDandOCC'][j].baseline_occupancy,
              "reporting_occupancy":this.getNSdata[2]['calculateCDDandOCC'][j].reporting_occupancy,
          })
       
       
    }
       this.consumptionLineChart(this.graphData);
       this.grossSavingLineChart(this.data);
       console.log(this.data)
     }

consumptionLineChart(data){
      this.nameArr =["Adj Baseline Consumption","Baseline Consumption","Reporting Consumption"];
      this.setGraphdatas =  JSON.parse(data.data.data);
      console.log(this.setGraphdatas )
      this.GraphReport.consumptionDatas(this.setGraphdatas,"lineChart1",this.nameArr,"adj_reporting_consumption")
      this.GraphReport.savingDatas(this.setGraphdatas,"lineChart3")
        }
grossSavingLineChart(data){
  console.log(data)
  this.GraphReport.twoDatalineGraph(data,"lineChart2")
 }
 setBestBtVal(data){
    let BT1,BT2;
  BT1= this.getNsBsdata.getBt1();
  BT2= this.getNsBsdata.getBt2();
  if(parseInt(BT1)>parseInt(BT2)){
      this.bstCDD = BT1;
 }
 else{
     this.bstCDD = BT2;
 }
 }
  }
 