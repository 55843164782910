import { Formdata } from 'src/app/localStorage/formdata';
import { analyzeAndValidateNgModules } from '@angular/compiler';

export class AllMNVcal {
     getBaselineData:any;
     getReportingData:any;
     getModelOutput:any;
     NraData:any;
     calculatedValues = [];
     RoutineAdjData = [];
     parseAdjusted = [];
     getNsBsdata = new Formdata();
     /////CDD////////
     Cdd = [];
    
    dataLocalStorage  = new Formdata();
   
    constructor( ){}
    
                            /*-----------------------------
                                GET LOCAL STORAGE HERE
                            ------------------------------*/
        getBSForData(){
                this.getBaselineData  =  JSON.parse(this.getNsBsdata.getLocalStoragIP1());
                console.log( this.getBaselineData )
                this.getReportingData =  JSON.parse(this.getNsBsdata.getLocalStoragIP2());
                this.getModelOutput   =  JSON.parse(this.getNsBsdata.getModelOutput());
                this.NraData =           JSON.parse(this.getNsBsdata.getNRAdata());
                this.parseAdjusted   =   JSON.parse(this.getModelOutput.data.data)
                this.getGrosaving( this.getBaselineData,this.getReportingData);
                this.RoutineAdjustment(this.parseAdjusted,this.getBaselineData,this.getReportingData, this.NraData)
                this.calculateCDD(this.parseAdjusted,this.getBaselineData,this.getReportingData, this.NraData)
        }

                            /*-----------------------------
                                      GROSS SAVINGS
                             ------------------------------*/
        getGrosaving(dataBaseline,dataReporting){
            let TempGrosVal = 0;
            let TempPerGrosVal = 0;
            let getGrossVal = [];
            let tmpdataBaseline:any;
            let tmpdataReporting:any;
            for(var val in dataBaseline)
            { 
                tmpdataBaseline  =   parseInt(dataBaseline[val].dependent_variable);  
                tmpdataReporting =   parseInt(dataReporting[val].dependent_variable);    
                                
                            /*---------------GrossSaving------------------- */
            
                TempGrosVal =   tmpdataBaseline - tmpdataReporting;
               // console.log(parseInt(dataBaseline[val].dependent_variable) );
              //  console.log(parseInt(dataBaseline[val].Timestamp));                
                            /*------------- Percentage GrossSaving -------------*/
            
                TempPerGrosVal = (TempGrosVal/ tmpdataBaseline)*100;
    
                getGrossVal.push({"TempGrosVals":TempGrosVal,"TempPerGrosVals": this.roundOff(TempPerGrosVal,1000),
                "tmpdataBaseline":tmpdataBaseline,"tmpdataReporting":tmpdataReporting,
                "Timestamp":dataReporting[val].Timestamp})  ;               
            }
            
                    this.calculatedValues.push({"grossVals":getGrossVal});
            
        }
                             /*-----------------------------------
                                     Routine Adjustment
                              ------------------------------------*/
    RoutineAdjustment(AdjstBaseline,dataBaseline,dataReporting,nraData){
        let AdjBaseline:any;
        let RoutineAdj:any;
        let RoutineAdjCost :any;
        let NonRoutineAdjCost:any;
        let EnergyCost = this.getModelOutput.cost;
        let TotalAdjustment:any;
        let RoutineAdjustment = [];
        let tmpdataBaseline:any;
        let tmpdataReporting:any;
        let tmpdataAdjustment:any;
        let TemPernetSavings:any;
        let TemPerSavings:any;
        AdjBaseline =AdjstBaseline;
        console.log(nraData)
        for(var val in dataBaseline)
        {
            tmpdataBaseline =  parseInt(dataBaseline[val].dependent_variable);
            tmpdataReporting =  parseInt(dataReporting[val].dependent_variable);
            tmpdataAdjustment = parseInt(AdjBaseline[val].adj_baseline_consumption);
            
                RoutineAdj = tmpdataAdjustment - tmpdataBaseline;
            
                        //---------Routine  Adjacement IN cost Saving-------------//
            
                RoutineAdjCost =  RoutineAdj * EnergyCost;   
            
                    //---------Non Routine  Adjacement IN cost Saving-------------//
            
                NonRoutineAdjCost =  parseInt(nraData[val]) * EnergyCost;   

                    //--------------Total Adjustment-------------------------------//
            
                TotalAdjustment =  parseInt(nraData[val])  +  RoutineAdj;   
                
                    //--------------Net & %Net savings-----------------//

                TemPerSavings =     (tmpdataAdjustment - tmpdataReporting + parseInt(nraData[val]))
                TemPernetSavings =   TemPerSavings/tmpdataBaseline*100;

            this.RoutineAdjData.push(RoutineAdj);  
            console.log(nraData[val]);
            RoutineAdjustment.push(
                                        {
                                        "RoutineAdjs":RoutineAdj,
                                        "RoutineAdjCostSavings":this.roundOff(RoutineAdjCost,1000),
                                        "NonRoutineAdjCostSaving":this.roundOff(NonRoutineAdjCost,1000),
                                        "NonRoutineAdj":nraData[val],
                                        "TotalAdjustments":TotalAdjustment,
                                        "TemPerSavings": this.roundOff(TemPerSavings,1000),
                                        "TempnetSavings": this.roundOff(TemPernetSavings,1000)
                                        }
                                    )
        }
            this.calculatedValues.push({"RoutineAdjustments":RoutineAdjustment})
        
    }
            
                           
       
                              /*-----------------------------------
                                        CDD Calculation
                               ------------------------------------*/      
    calculateCDD(AdjstBaseline,dataBaseline,dataReporting,nraData){
        let AdjBaseline:any;
        let ChangeInCdd:any;
        let ChangeInOCC:any;
        let calculateCDDandOCC =[];
        let tmpdataBaseline:any;
        let tmpBaselineOcc:any;
        let tmpReportingOcc:any;
        let tmpReportingcnsptn:any;
        let tmpBaselinecnsptn:any;
        AdjBaseline = AdjstBaseline;
        console.log(AdjBaseline)
        for(var val in AdjBaseline ){
            
            tmpdataBaseline =     parseInt(dataBaseline[val].independent_variable2);
            tmpBaselineOcc =      parseInt(AdjBaseline[val].baseline_occupancy);
            tmpReportingOcc =     parseInt(AdjBaseline[val].reporting_occupancy);
           
            tmpBaselinecnsptn =   parseFloat(AdjBaseline[val].baseline_cdd);
            tmpReportingcnsptn =  parseFloat(AdjBaseline[val].reporting_cdd);
            
            ChangeInCdd = this.checkNull(((tmpBaselinecnsptn - tmpReportingcnsptn)/tmpBaselinecnsptn) * 100) ;
            
            ChangeInOCC =  this.checkNull((( tmpBaselineOcc -  tmpReportingOcc)/ tmpBaselineOcc) * 100) ;     
        
            calculateCDDandOCC.push({"ChangeInCdd": this.roundOff(ChangeInCdd,100),
                                    "ChangeInOCC": this.roundOff(ChangeInOCC,100),
                                    "Baseline_cdd":this.roundOff(tmpBaselinecnsptn,10),
                                    "Reporting_cdd":this.roundOff(tmpReportingcnsptn,10),
                                    "baseline_occupancy":tmpBaselineOcc,
                                    "reporting_occupancy":tmpReportingOcc
                                    })

                                    

        }
        this.calculatedValues.push({"calculateCDDandOCC":calculateCDDandOCC});
        console.log("Baseline")
        console.log(this.calculatedValues);
        this.getNsBsdata.setACSdata(JSON.stringify(this.calculatedValues));
    }   
    roundOff(val,roundRange){
        return Math.round(val * roundRange)/roundRange;
    }
    checkNull(val){
        if(val){
            return val;
        }else{
            return 0;
        }
       
    }
     
}