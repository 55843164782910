import { Component, OnInit } from '@angular/core';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { Router } from '@angular/router';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-mnv-steps',
  templateUrl: './mnv-steps.component.html',
  styleUrls: ['./mnv-steps.component.css']
})
export class MnvStepsComponent implements OnInit {
  data:any;
  flag = true;
  constructor(private FormFlag:FormFlags,private router:Router,private activeLInks:FormFlags,
     private dataShare:DatashareService ) { }
 ngOnInit() {
     
     let currentUrl = this.router.url; 
     console.log(currentUrl + "hello")
   } 
   
   

   activeRoute(routeLink){
     this.activeLInks.setActiveList(routeLink)
         // this.setActiveList(routeLink)
    }

      
      
      
      
   
  
  

}
