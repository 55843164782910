import { Component, OnInit } from '@angular/core';
import { Formdata } from 'src/app/localStorage/formdata';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';

import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { DialogOverviewExampleDialog } from '../../dashboard.component';
import { ArrayLableService } from 'src/app/services/array-lable.service';

@Component({
  selector: 'app-model-output',
  templateUrl: './model-output.component.html',
  styleUrls: ['./model-output.component.css']
})
export class ModelOutputComponent implements OnInit {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  slop1:any;
  slop2:any;
 
  modelOps:any;
  data:any;
  ModelOp:any;
  dataTabl:any = [];
  dataTabl1:any = [];
  KeyArray = [];
  dataSource:any
  displayedColumns: string[]
  displayedColumns1: string[]
  checkTotal:any;
  checkVarFlag :any;
  getVarStatus:any;
  performCal:any;
  getID:any;
  projectID:any;
  dataSource1:any;
  outputFor2var:any;
  Temp:any;
  TempVar1:any;
  TempVar2:any;
  performCalforOne = false;
  hideRegressOpt = true;
  projDetails:any;
  projectName:any;
  getMNVopt:any;
  getBuildata:any;
  modelType:any;

  VarModel1:any;
  VarModel2:any;
  valVarModel1:any;
  valVarModel2:any;
  OutputData:any;
  singleVardata:any;
notification:boolean = false

  IMPVP = [{"parameters":"r_square","text":"Should be >0.75"},{"parameters":"cvrmse","text":"Should be <0.01"},{"parameters":"t_statX1","text":"Should be > 2 & < -2"},{"parameters":"t_statX2","text":"Should be > 2 & < -2"},
           {"parameters":"relative_precission","text":"should be minimum"}, {"parameters":"abs_precission","text":"should be minimum"},{"parameters":"absolute_p","text":"should be minimum"}];

  
  constructor(public formDatas:Formdata,private router:Router,private FormFlag:FormFlags,private _getData:SerDATAmnvService,
    private _bottomSheet: MatBottomSheet,private _dataLables:ArrayLableService) { }
  panelOpenState = false;
  ngOnInit() {
   
    //--------------Project Details--------------//
    this.projDetails = JSON.parse(this.formDatas.getProjectReg());
    this.projectName = this.projDetails[0].project_name;
    
    this.getMNVopt = this.formDatas.getLocalStoragMNVopt();
    this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
    
    this.modelType = this.formDatas.getModelName(); 
      //--------------Project Details--------------// 
    this.modelOps = this.formDatas.getModelOutput();
    this.getID = this.formDatas.getLocalStoragRegProj();
    this.projectID = JSON.parse(this.getID);
    this.data = JSON.parse(this.modelOps);
    
   
   //  this.Temp =   this.data.data.param.model_name.split("_");
 
   
    //this.showTable1(this.data);
    this.checkVarFlag = this.formDatas.gtLocalStoragBuildMNV();
    this.getVarStatus = JSON.parse(this.checkVarFlag);
   if(this.getVarStatus.model_type==2){
      this.regresseionForOneVar(this.data)
      this.performCal = true;
      this.getVarStatus.model_type = 1;
      this.slop2 = this.data.data.param.statnderd_equation_obtained;
      this.Temp =   this.data.data.param.model_name.split("_");
      this.TempVar2 =  this.Temp [1];
      this.formDatas.setBt2(JSON.stringify(this.TempVar2));
      this.formDatas.setBt1(JSON.stringify(0));
     
    }
    else{
       this.formDatas.setBt2(JSON.stringify(0));
       this.showTable1(this.data);
       this.performCal = false;
       this.performCalforOne = true;
       this.hideRegressOpt = false;
      }
  
  }
  openBottomSheet(): void {
    console.log("helo")
    this._bottomSheet.open(ShowDailogComponentbottom);
    this.OutputData =  JSON.parse(this.formDatas.getModelOutput());
    this.notification = true;
    console.log(this.OutputData.data.param.r_square)
   this.singleVardata = this.formDatas.getsetModelFor1var();
   this.valVarModel2 = this.singleVardata.data.param.r_square
  
   this.valVarModel1 = this.OutputData.data.param.r_square
  
   if(this.valVarModel1>this.valVarModel2){
    this.VarModel1 = "TWO Variable Model"
    this.VarModel2 = "ONE Variable Model"
  
   }
   else{
    this.VarModel2 = "TWO Variable Model"
    this.VarModel1 = "ONE Variable Model"
   }
  }

  regresseionForOneVar(data){
    this._dataLables.getArrayLable(key)
    let tempdata =  data.data.param.model_name.split("_");
    this.TempVar2 = tempdata[1];
    console.log(tempdata)
    for(var key in data.data.param){
      if( typeof(data.data.param[key])!="object"){
      //statnderd_equation_obtained
         this.checkTotal = key.split("_");
         
            //--------------- Removed 'Total' Values Here-----------------//
          if(this.checkTotal[0] != "total"){
                console.log(data.data.param)
                this.dataTabl.push({ Parameter:this._dataLables.getArrayLable(key), Actual:data.data.param[key], IMPVP: this.commentIMPVP(key)}) 
            }
           
           //-------------Removed 'Total' Values Here-----------------//
      }else
        {
        if(key=='coef'){
          this.dataTabl.push({ Parameter:'CDD', Actual:data.data.param[key].cdd, IMPVP: this.commentIMPVP(key)}) 
          this.dataTabl.push({ Parameter:'Occupancy', Actual:data.data.param[key].occupancy, IMPVP: this.commentIMPVP(key)}) 
          }
          if(key=='p_value'){
            this.dataTabl.push({ Parameter:this._dataLables.getArrayLable(key)+" CDD", Actual:data.data.param[key].cdd, IMPVP: this.commentIMPVP(key)}) 
            this.dataTabl.push({ Parameter:this._dataLables.getArrayLable(key)+" Occupancy", Actual:data.data.param[key].occupancy, IMPVP: this.commentIMPVP(key)}) 
          }
          if(key=='t_stat'){
            this.dataTabl.push({ Parameter:this._dataLables.getArrayLable(key)+" CDD", Actual:data.data.param[key].cdd, IMPVP: this.commentIMPVP(key+"X1")}) 
            this.dataTabl.push({ Parameter:this._dataLables.getArrayLable(key)+" Occupancy", Actual:data.data.param[key].occupancy, IMPVP: this.commentIMPVP(key+"X2")}) 
          }
      }
      
        
    }
  //var uniqueItems = Array.from(new Set(this.KeyArray))
  this.displayedColumns = ["Parameter","Actual","IMPVP"]
  this.dataSource = this.dataTabl;
  
  this.outputFor2var = data;
   
  }

  commentIMPVP(key){
    for(var i = 0; i<this.IMPVP.length; i++)
      { 
        if(this.IMPVP[i].parameters==key)
          {  
            return this.IMPVP[i].text;
          }
       
      }
  }
  submit(){
   this.getVarStatus.model_type = 1;
    console.log(this.getVarStatus);
    this._getData.regProjctDetails(this.getVarStatus,this.projectID.id).subscribe(
      res => this.showData(res),
      error => console.error(error));
   
  }
  showData(data){
      console.log(data);
      this._getData.makeRegxModel( this.projectID.id).subscribe(
      res => this.showResultOneVar(res),
      error => console.error(error));  
  }
  routeComponent(){
    this.router.navigateByUrl('/mnv-forms/nra');
  
  }
  submitNext(){
     this.checkRsquare();
     //this.openBottomSheet()
    this.router.navigateByUrl('/mnv-forms/nra'); 
    }
  showResultOneVar(data){
   // console.log(data);
   //-----Change Flags----//

    this.performCalforOne = true;
    this.hideRegressOpt = false;
  //-----Change Flags----//
  this.formDatas.setModelFor1var(JSON.stringify(data));
  this.showTable1(data);
  this.openBottomSheet()
  }
  showTable1(data){
    this.slop1 = data.data.param.statnderd_equation_obtained;
    let tempdata =  data.data.param.model_name.split("_");
    this.TempVar1 = tempdata[1];
    this.formDatas.setBt1(JSON.stringify(this.TempVar1));

    for(var key in data.data.param){
      if( typeof(data.data.param[key])!="object"){
      
        //--------------- Removed Total Values Here-----------------//
          this.checkTotal = key.split("_");
          if(this.checkTotal[0] != "total"){
                this.dataTabl1.push({ Parameter:this._dataLables.getArrayLable(key), Actual:data.data.param[key], IMPVP: this.commentIMPVP(key)})  }
           //-------------Removed Total Values Here-----------------//
      }else
        {
        if(key=='coef'){
          this.dataTabl1.push({ Parameter:key+"X1", Actual:data.data.param[key].x1, IMPVP: this.commentIMPVP(key)}) 
          this.dataTabl1.push({ Parameter:key+"X2", Actual:data.data.param[key].x2, IMPVP: this.commentIMPVP(key)}) 
          }
          if(key=='p_value'){
            this.dataTabl1.push({ Parameter:key+"X1", Actual:data.data.param[key].x1, IMPVP: this.commentIMPVP(key)}) 
            this.dataTabl1.push({ Parameter:key+"X2", Actual:data.data.param[key].x2, IMPVP: this.commentIMPVP(key)}) 
          }
          if(key=='t_stat'){
            this.dataTabl1.push({ Parameter:key+"X1", Actual:data.data.param[key].x1, IMPVP: this.commentIMPVP(key+"X1")}) 
            this.dataTabl1.push({ Parameter:key+"X2", Actual:data.data.param[key].x2, IMPVP: this.commentIMPVP(key+"X2")}) 
          }
      }
      
        
    }
    //var uniqueItems = Array.from(new Set(this.KeyArray))
    this.displayedColumns1 = ["Parameter","Actual","IMPVP"]
    this.dataSource1 = this.dataTabl1;
    this.outputFor2var = data;
  
   
  }
  checkRsquare(){
    console.log(this.outputFor2var);
    if(parseInt(this.outputFor2var.data.param.r_square)>parseInt(this.data.data.param.r_square)){
      this.formDatas.setModelOutput(JSON.stringify(this.outputFor2var));
    }
    else{
      this.formDatas.setModelOutput(JSON.stringify(this.data));
    }
  }
}




@Component({
  selector: 'app-show-dailog',
  templateUrl: './bottomDailogChek.html',
  
})
export class ShowDailogComponentbottom implements OnInit {
 
  constructor(private _bottomSheetRef: MatBottomSheetRef<ShowDailogComponentbottom>,private formDatas:Formdata ) {}
   openLink(event: MouseEvent): void {
   // this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  VarModel1:any;
  VarModel2:any;
  valVarModel1:any;
  valVarModel2:any;
  OutputData:any;
  singleVardata:any;
  note:any
  ngOnInit(){
    this.OutputData =  JSON.parse(this.formDatas.getModelOutput());
    console.log(this.OutputData.data.param.r_square)
   this.singleVardata = this.formDatas.getsetModelFor1var();
   this.valVarModel2 = this.singleVardata.data.param.r_square
  
   this.valVarModel1 = this.OutputData.data.param.r_square
  
   if(this.valVarModel1>this.valVarModel2){
    this.VarModel1 = "TWO Variable Model"
    this.VarModel2 = "ONE Variable Model"
  
   }
   else{
    this.VarModel2 = "TWO Variable Model"
    this.VarModel1 = "ONE Variable Model"
   }
}
}


