import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-dailog',
  template: `
    <p>
      bottom-dailog works!
    </p>
  `,
  styleUrls: ['./bottom-dailog.component.css']
})
export class BottomDailogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
