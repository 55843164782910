import { Component, OnInit,ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SerDATAmnvService } from '../services/ser-datamnv.service';
import { Formdata } from '../localStorage/formdata';
import { AuthService } from '../services/AuthServices/auth.service';
import { TokenService } from '../services/AuthServices/token.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  implements OnInit  {
  myForm:FormGroup;
  loggedIn:any;
  

  ngOnInit() {
    this.myForm =  this.formBuilder.group({
      'project_name': [null, Validators.required],
     
     });
     this._auth.authStatus.subscribe(value=> this.loggedIn = value);
     
  }
 constructor(private _getData:SerDATAmnvService,private formBuilder: FormBuilder,public formDatas:Formdata,
  private _auth:AuthService,private token:TokenService,private router:Router){ } 
 showFiller = false;
 data:any;
 
onSubmit(data){
    this._getData.regProjctName(data).subscribe(
    res => this.showData(res),
    error => console.error(error));}

logout(event:MouseEvent){
    event.preventDefault();
    this._auth.changeAuthStatus(false);
    this.token.remove();
    this.router.navigateByUrl('/login');
   }    
showData(res){
     this.formDatas.setProjectReg(JSON.stringify(res));
  }
  
}
