import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mnv-forms',
  templateUrl: './mnv-forms.component.html',
  styleUrls: ['./mnv-forms.component.css']
})
export class MnvFormsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
