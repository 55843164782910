import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { CustomFormValidationsService } from 'src/app/services/Alerts/custom-form-validations.service';
import { DatashareService } from 'src/app/services/modal/datashare.service';
@Input('cdkAutosizeMaxRows')
@Component({
  selector: 'app-ip-data',
  templateUrl: './ip-data.component.html',
  styleUrls: ['./ip-data.component.css']
})
export class IpDataComponent implements OnInit,ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }


constructor(private formBuilder: FormBuilder,public formDatas:Formdata,
  private _getData:SerDATAmnvService,private router:Router,private FormFlag:FormFlags,
  private validate:CustomFormValidationsService,private dataSharing:DatashareService) { }
 
  myForm:FormGroup;
  row:string;
  disable:boolean;
  DateEntered:any;
  timeStampLenght:Number;
  saveData:boolean
  formFlag:boolean;
  dataBS:any;
  projDetails:any;
  projectId:any;
  inputData1:any;
  projectName:any;
  getMNVopt:any;
  getBuildata:any;
  modelType:any
  bsData:any
ngOnInit() {
    //--------------Project Details--------------//
    this.formDatas.setLocalStoragIP1("");

        this.projDetails = JSON.parse(this.formDatas.getProjectReg());
        this.projectName = this.projDetails[0].project_name;
        this.getMNVopt = JSON.parse(this.formDatas.getLocalStoragMNVopt());
        this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
        this.modelType =  this.formDatas.getModelName();
    //--------------Project Details--------------// 
      
    this.formFlag = true;
    this.saveData = false;
    this.myForm =  this.formBuilder.group({
      'Timestamp':['', Validators.required],
      'dependent_variable':['', Validators.required],
      'independent_variable2': [{value: '', disabled:this.flagOccupency()}]
     });
  //   console.log(this.datashare.formNotes)
    
  }
  onSubmit(){
    this.formDatas.setLocalStoragIP1(JSON.stringify(this.dataBS));
    this.projDetails = this.formDatas.getProjectReg();
    this.projectId= JSON.parse(this.projDetails);
    this.bsData={"data":  this.dataBS,'project_id':this.projectId[0].id}
    this.reqBsData(this.bsData);  
   }

svData(data){
   this.dataBS =  this.validate.validateData(data,this.getBuildata.model_type)
   data = null;
   if(this.dataBS){
      this.formFlag = false
   }

  
}
  
reqBsData(data){
  this._getData.regBSdata(data).subscribe(
  res => this.getData(res),
  error => console.error(error));
}

getData(data){
  if(data==true){
    this.dataSharing.links[5].status = false
    this.router.navigateByUrl('/mnv-forms/ip-data2');
    console.log(data)
  }
 
}

flagOccupency(){
  this.inputData1 = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
   if(this.inputData1.model_type==2){
      this.disable=false;
    }else{ this.disable=true;}
  return this.disable;
}

}

