import { HttpClient, HttpInterceptor, HttpHeaders,HttpHandler,HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators'; 
import { LoaderService } from '../services/loader.service';
import { GetAllAlertsService } from './Alerts/get-all-alerts.service';
import Swal from 'sweetalert2'
@Injectable({providedIn:'root'})
export class App implements HttpInterceptor {
    constructor(public loaderService: LoaderService,public dataAlerts:GetAllAlertsService){}
     alerts =    new  GetAllAlertsService();
     
    handleError(error:import("@angular/common/http").HttpErrorResponse){
         return throwError(error)
    }
    intercept(req: import("@angular/common/http").HttpRequest<any>,
              next: import("@angular/common/http").HttpHandler): Observable<import("@angular/common/http").HttpEvent<any>> {
              this.loaderService.show();
              const headers = new HttpHeaders({
                'Content-Type':'application/json; charset=utf-8'  });
        
                const clone = req.clone({
                    headers : headers
                });
                return next.handle(clone)
       .pipe(
              catchError(err => {
                console.error(err);
                this.dataAlerts.showAlert(err.statusText)
                return throwError("Error thrown from catchError") }),
              finalize(() => this.loaderService.hide())
            );
        
       // throw new Error("Method not implemented.");
    }
    
   
}
 