import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatashareService {
Modal:string
  constructor() { }

  getModelType(modelType){
    this.Modal = modelType;
  }

  /*-------Side Nav List-------*/
  links = [
    {'routerLink':'project-registration','icon':'fa fa-file-text ','name':'Name Of Project','status':false},
    {'routerLink':'ecm-details','icon':'fa fa-suitcase','name':'Project ECM Details','status':true},
    {'routerLink':'mnv-option','icon':'fa fa-check-square','name':'Select M&V Option for (Boundary of Measurement)','status':true},
    {'routerLink':'mnv-model','icon':'fa fa-suitcase','name':'Build the M&V Model','status':true},
    {'routerLink':'ip-data','icon':'fa fa-file-text ','name':'Input data for Baseline Period','status':true},
    {'routerLink':'ip-data2','icon':'fa fa-file-text ','name':'Input data for Reporting Period','status':true},
    {'routerLink':'model-output','icon':'fa fa-pie-chart ','name':' Model Output','status':true},
    {'routerLink':'saving-summary','icon':'fa fa-pie-chart ','name':'Method Saving Summary','status':true}
]
  /*-------Side Nav List-------*/
 
  /*----Validations Notes------*/
formNotes = [
               {'formNote':'Please Enter Date in DD-MM-YY Format.(e.g 01-01-2020)','default':'<i class="fa fa-circle"></i>'},
               {'formNote':'Data Rows Should be Same of Each Data Field','default':'<i class="fas fa-circle"></i>'},
               {'formNote':'Please Enter Date in Timestamp Column','default':'<i class="fas fa-circle"></i>'},
               {'formNote':'Please Enter Numeric Values in Other Colums Rather Than Timestamp','default':'<i class="fas fa-circle"></i>'}
            ]
   /*----Validations Notes------*/          
}

