import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { DatashareService } from 'src/app/services/modal/datashare.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
} 

@Component({
  selector: 'app-project-registration',
  templateUrl: './project-registration.component.html',
  styleUrls: ['./project-registration.component.css']
})


export class ProjectRegistrationComponent implements OnInit {
  constructor(private formBuilder: FormBuilder,public formDatas:Formdata,
    private _getData:SerDATAmnvService,private router:Router,private FormFlag:FormFlags,
    private dataShare:DatashareService ) { }
 
  myForm:FormGroup;
  selected:string;
  flag = true;
  inputText = true;
  items: FormArray;
  projDetails:any;
  data:any;
  routeLinkEcm:any;
 
  projectName:any;
  matcher = new MyErrorStateMatcher();

  ngOnInit() {
   //--------------Project Details--------------//
   this.projDetails = JSON.parse(this.formDatas.getProjectReg());
   this.projectName = this.projDetails[0].project_name;
     //--------------Project Details--------------//
   this.myForm =  this.formBuilder.group({
      'organization_name': [null, Validators.required],
      'annual_bill': [null, Validators.required],
      'type_of_building':[null, Validators.required],
      'IGA_conducted': [null, Validators.required],
      'ecm_bill_savings':[null],
      'energy_type':[null, Validators.required],
     });
     
     //let currentUrl = this.router.url;
     this.FormFlag.setActiveList( this.router.url) 
   //  console.log(currentUrl)
  }
  disableSelect = new FormControl(false);
    onSubmit(post){
          let project_id;
          this.projDetails = this.formDatas.getProjectReg();
          this.data = JSON.parse(this.projDetails);
          project_id = this.data[0].id;
        
          post.project_name = this.data[0].project_name;
          post.id = project_id;
          post._method = "put";
          this.formDatas.setLocalStoragRegProj(JSON.stringify(post));
          this._getData.regProjctDetails(post,this.data[0].id).subscribe(
          res => this.showData(res),
          error => console.error(error));
   }
  change(data){
     if(data=="Yes"){
      this.flag = false;
    }else { this.flag = true;}
  
  }
  showData(data){
   console.log(this.myForm.value);
    if(this.myForm.value.IGA_conducted=="Yes"){
      this.router.navigateByUrl('/mnv-forms/ecm-details');
     // this.FormFlag.setformFlagStatus("ecmDetails")
      this.dataShare.links[1].status = false
    }
    else
    { 
      this.router.navigateByUrl('/mnv-forms/mnv-option'); 
    //  this.FormFlag.setformFlagStatus("mnvOpt")
      this.dataShare.links[2].status = false
    }
 }

}
