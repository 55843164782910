import { Component, OnInit } from '@angular/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-mnv-option',
  templateUrl: './mnv-option.component.html',
  styleUrls: ['./mnv-option.component.css']
})
export class MnvOptionComponent implements OnInit {
  next:boolean = true;
  projDetails:any;
  data:any;
  mnvOption:any;
  mnvOptionData:any;
  projectName:any
  flagA = false;
  flagB=false;
  flagC=false;
  flagD=false;
  showTextA = false;
  showTextB = true;
  showTextC = true;
  showTextD = true;
  constructor(private formDatas:Formdata,private _getData:SerDATAmnvService,
    private router:Router,private FormFlag:FormFlags,   private dataShare:DatashareService) {  }
 
  getVal(data){
   this.changeStatus(data); 
   this.next = false;
   this.mnvOptionData = data;
   this.formDatas.setLocalStoragMNVopt(JSON.stringify(data));
  }
  ngOnInit() {
         /*------Active Links------*/
     this.FormFlag.setActiveList( this.router.url) 
         /*------Active Links------*/
      this.flagA = false;
      let project_ids
      this.projDetails = this.formDatas.getProjectReg();
      this.data = JSON.parse(this.projDetails);
      project_ids = this.data[0].id;
       //--------------Project Details--------------//
      this.projDetails = JSON.parse(this.formDatas.getProjectReg());
      this.projectName = this.projDetails[0].project_name;
      //--------------Project Details--------------//
   }
  
  onSubmit(){
    this.projDetails = this.formDatas.getProjectReg();
    this.data = JSON.parse(this.projDetails);
    this.mnvOption = {"report_type":this.mnvOptionData,"id":this.data[0].id,"_method":"put"};
    this.reqEcmdetails(this.mnvOption,this.data[0].id)
   }
  
  
  changeStatus(flag){
    if(flag == "A"){
      this.flagA = true;
      this.flagB = false;
      this.flagC = false;
      this.flagD = false;
    }
    if(flag == "B"){
      this.flagB = true;
      this.flagA = false;
      this.flagC = false;
      this.flagD = false;
    }
    if(flag == "C"){
      this.flagC = true;
      this.flagA = false;
      this.flagB = false;
      this.flagD = false;
    }
    if(flag == "D"){
      this.flagD = true;
      this.flagA = false;
      this.flagB = false;
      this.flagC= false;
    }
   }
   checkLog(flag){
     
    if(flag == "A"){
      this.showTextA = false;
      this.showTextB = true;
      this.showTextC = true;
      this.showTextD = true;
    }
    if(flag == "B"){
      this.showTextB = false;
      this.showTextA = true;
      this.showTextC = true;
      this.showTextD = true;
    }
    if(flag == "C"){
      this.showTextC = false;
      this.showTextA = true;
      this.showTextB = true;
      this.showTextD = true;
    }
    if(flag == "D"){
      this.showTextD = false;
      this.showTextA = true;
      this.showTextB = true;
      this.showTextC= true;
    }
   }
   reqEcmdetails(dataMnv,id){
      this._getData.regProjctDetails(dataMnv,id).subscribe(
      res => this.getResponse(res),
      error => console.error(error));
   }
   getResponse(res){
    this.router.navigateByUrl('/mnv-forms/mnv-model');
    this.dataShare.links[3].status = false
   // this.FormFlag.setformFlagStatus("mnvOpt")
  }
}
