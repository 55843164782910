import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { ProjectDetailsService } from 'src/app/services/project-details.service';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-mnv-model',
  templateUrl: './mnv-model.component.html',
  styleUrls: ['./mnv-model.component.css']
})
export class MnvModelComponent implements OnInit,ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
 
  myForm:FormGroup;
  disable = true;
  mnvOption:any;
  mnvOptionData:any
  projDetails:any;
  mnvOptions:any;
  data:any;
  projectName:any;
  getMNVopt:any
  savingType = "";
  savingTypeModel:any;
  constructor(private formBuilder: FormBuilder,
    public formDatas:Formdata,private _getData:SerDATAmnvService,
    private router: Router,private FormFlag:FormFlags,
    private messageService: ProjectDetailsService,private dataSharing:DatashareService, private dataShare:DatashareService) { }
    ngOnInit() {
      this.formDatas.setLocalStoragIP1("");
        /*------Active Links------*/
    this.FormFlag.setActiveList( this.router.url) 
        /*------Active Links------*/
    
      //--------------Project Details--------------//
     this.projDetails = JSON.parse(this.formDatas.getProjectReg());
     this.projectName = this.projDetails[0].project_name;
     
     this.getMNVopt = this.formDatas.getLocalStoragMNVopt();
     
       //--------------Project Details--------------// 
      
    this.myForm =  this.formBuilder.group({
      'frequency_of_data':['', Validators.required],
      'model_name':['', Validators.required],
      'independent_variable1':[true, Validators.required],
      'independent_variable2':[false, Validators.required],
      "model_type" :['']

     });
    
    
  }
  onChange(){
    console.log(this.myForm.value.model_name)
    if(this.myForm.value.model_name=='ACS' ){
         this.dataSharing.Modal="ACS (Avoided Cost Saving)"
      }else{
       this.dataSharing.Modal="Normalized"
      }
  }
  onSubmit(data){
    console.log(JSON.stringify(data))
    if(data.independent_variable2== true ){
      data.model_type = 2;
    }else{
      data.model_type = 1;
    }
     this.projDetails = this.formDatas.getProjectReg();
     this.data = JSON.parse(this.projDetails);
     data._method = "put";
     this.updateData(data,this.data[0].id);
    
     this.formDatas.setLocalStoragBuildMNV(JSON.stringify(data));
  
  }
  updateData(res,id){
        this._getData.regProjctDetails(res,id).subscribe(
        res => this.reqMnvModel(res),
        error => console.error(error));
    }
  reqMnvModel(res){
        console.log(res); 
        this.router.navigateByUrl('/mnv-forms/ip-data');
        this.dataShare.links[4].status = false
     //    this.FormFlag.setformFlagStatus("Baseline") 
        
        
        } 

       

  sendMessage(): void {
      // send message to subscribers via observable subject
      this.messageService.sendMessage('Message from Home Component to App Component!');
  }

  clearMessages(): void {
      // clear messages
      this.messageService.clearMessages();
  }
  }


