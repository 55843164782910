import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SerDATAmnvService } from '../services/ser-datamnv.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Formdata } from '../localStorage/formdata';
import { Router } from '@angular/router';
import { AuthService } from '../services/AuthServices/auth.service';
import { TokenService } from '../services/AuthServices/token.service';
import { GetAllAlertsService } from '../services/Alerts/get-all-alerts.service';

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  showRout:boolean;
  animal: string;
  name: string;
  loggedIn:any;
  constructor(public dialog: MatDialog,
     private _auth:AuthService,private token:TokenService,private router:Router,private alert: GetAllAlertsService) { }

  ngOnInit() {
    console.log("this.loggedIn")
    this._auth.authStatus.subscribe(value=> this.loggedIn = value);
    this.showRout = true;
    if(this.loggedIn){
      console.log(this.loggedIn)
      this.router.navigateByUrl('/dashboard');
    }
    else{
     this.router.navigateByUrl('/login');
    }
  }
  showRoutes(){
    this.showRout = false;
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  
}   
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.html',
})


                                                                                        /*----------------------------------------------
                                                                                                        for dailog slector
                                                                                        -----------------------------------------------*/
export class DialogOverviewExampleDialog implements OnInit {
  myForm:FormGroup;
  ngOnInit() {
     this.myForm =  this.formBuilder.group({
      'project_name': [null, Validators.required],
     });
  }
  showFiller = false;

  constructor(private _getData:SerDATAmnvService,private formBuilder: FormBuilder,private alert: GetAllAlertsService,
              public formDatas:Formdata,private router:Router, public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
 
 logout(event:MouseEvent){
  event.preventDefault();
 
}
onSubmit(data){
    this._getData.regProjctName(data).subscribe(
    res => this.reqRegproject(res));
   // error => this.alert.showAlert(error.error.error));
 

}
reqRegproject(res){
   this.router.navigateByUrl('mnv-forms/project-registration');
   this.formDatas.setProjectReg(JSON.stringify(res));
  }

}

