import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SerDATAmnvService {
  //private _baseURL = "http://asus:8000/api";
  private _baseURL = "http://ipmvp.hetadatain.com/api";
  constructor( private http : HttpClient) { }
   

  
  regProjctName(data){
      return  this.http.post(`${this._baseURL}/create_project`,data,{ headers: {'Content-Type':'application/json; charset=utf-8'} } )                      
     }                                                                                                                                                             
  errorHandler(errorHandler: any) {
    throw new Error("Method not implemented.");
  }

  regProjctDetails(data,id){
    return  this.http.post(`${this._baseURL}/project/${id}`,data,{
    headers: {'Content-Type':'application/json; charset=utf-8'}});
}   


   regEcmDtails(data){
      return  this.http.post(`${this._baseURL}/insert/ecm`,data,{
      headers: {'Content-Type':'application/json; charset=utf-8'}});
   }  

  regBSdata(data){
        return  this.http.post(`${this._baseURL}/baseline`,data,{
        headers: {'Content-Type':'application/json; charset=utf-8'}});
    }  

  regRSdata(data){
        return  this.http.post(`${this._baseURL}/reporting`,data,{
        headers: {'Content-Type':'application/json; charset=utf-8'}});
  }  

  makeRegxModel(id){
     return  this.http.get(`${this._baseURL}/regression/${id}`,{
     headers: {'Content-Type':'application/json; charset=utf-8'}});
  }  
     
}
