import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/AuthServices/network.service';
import { FormGroup,FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit , ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  myForm:FormGroup;
  public form ={
    email : null}
  constructor(
    private network:NetworkService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.myForm =  this.formBuilder.group({
      'email':['', Validators.required],
       });
  }

  onSubmit(dataForm){
    this.network.sendPasswordresetLink(dataForm).subscribe(
      data => this.handleResponse(data),
      error => console.log(error)
    );
  }
  handleResponse(data){
  this.form.email = null;
}
}
