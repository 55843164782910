import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { GetAllAlertsService } from 'src/app/services/Alerts/get-all-alerts.service';
import { CustomFormValidationsService } from 'src/app/services/Alerts/custom-form-validations.service';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-ip-data2',
  templateUrl: './ip-data2.component.html',
  styleUrls: ['./ip-data2.component.css']
})
export class IpData2Component implements OnInit,ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  constructor(private formBuilder: FormBuilder,public formDatas:Formdata,
    private _getData:SerDATAmnvService,private router: Router , private FormFlag:FormFlags,
    private alerts: GetAllAlertsService, private validate:CustomFormValidationsService,
    private dataSharing:DatashareService)  { }
 
    
  myForm:FormGroup;

  formFlag:boolean;
  data:any;
  disable:any
  projDetails:any;
  projectId:any;
  projectName:any;
  getMNVopt:any;
  getBuildata:any;
  modelType:any;
  dataRS:any
  rsData:any;
ngOnInit() {
  this.formDatas.setLocalStoragIP2("");
  this.formDatas.setModelOutput("");
    this.formFlag = true;
 //--------------Project Details--------------//
          this.projDetails = JSON.parse(this.formDatas.getProjectReg());
          this.projectName = this.projDetails[0].project_name;
          this.getMNVopt = this.formDatas.getLocalStoragMNVopt();
          this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
          this.modelType =  this.formDatas.getModelName();
 //--------------Project Details--------------// 
    this.myForm =  this.formBuilder.group({
      'Timestamp':['', Validators.required],
      'dependent_variable':['', Validators.required],
      'independent_variable2': [{value: '', disabled:this.flagOccupency()}]
     });
  
  }
onSubmit(){
     this._getData.makeRegxModel(this.rsData.project_id).subscribe(
      res => this.saveModelOp(res),
      error => console.error(error)); 
     }
svData(data){
  this.dataRS =  this.validate.validateData(data,this.getBuildata.model_type)
   data = null;
   if(this.dataRS){
    this.formDatas.setLocalStoragIP2(JSON.stringify(this.dataRS));
         this.formFlag = false
        this.projDetails = this.formDatas.getProjectReg();
        this.projectId= JSON.parse(this.projDetails);
        this.rsData={"data":  this.dataRS,'project_id':this.projectId[0].id} 
        this.reqRsData(this.rsData)
   }
}
 
reqRsData(data){
    this._getData.regRSdata(data).subscribe(
    res => this.getData(res),
    error => console.error(error));
  }
getData(res){
   if(res){
      this.formFlag = false;
    }else{
      this.formFlag = true;
    }

  }
  
saveModelOp(res){
  if(this.alerts.checkResponse(res)){
     this.formDatas.setModelOutput(JSON.stringify(res));
     this.dataSharing.links[7].status = false
     this.router.navigateByUrl('/mnv-forms/model-output');
  }
  
}




//******Disable/able Occupancy*******/
flagOccupency(){
  this.data = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
  if(this.data.model_type==2){
      this.disable=false;
    }else{ this.disable=true;}
  return this.disable;
}
//******Disable/Bnable Occupancy*******/
}
