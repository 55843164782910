import { BrowserModule } from '@angular/platform-browser';

import { TextFieldModule} from '@angular/cdk/text-field';
import { AppRoutingModule,routingComponents} from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent, DialogOverviewExampleDialog } from './dashboard/dashboard.component';
import { ProjectRegistrationComponent } from './dashboard/mnvForms/project-registration/project-registration.component';
import { EcmDetailsComponent } from './dashboard/mnvForms/ecm-details/ecm-details.component';
import { MnvStepsComponent } from './dashboard/mnv-steps/mnv-steps.component';
import { MnvFormsComponent } from './dashboard/mnv-forms/mnv-forms.component';
import { MnvOptionComponent } from './dashboard/mnvForms/mnv-option/mnv-option.component';
import { IpDataComponent } from './dashboard/mnvForms/ip-data/ip-data.component';
import { ModelOutputComponent, ShowDailogComponentbottom } from './dashboard/mnvForms/model-output/model-output.component';
import { SavingSummaryComponent } from './dashboard/mnvForms/saving-summary/saving-summary.component';
import { IpData2Component } from './dashboard/mnvForms/ip-data2/ip-data2.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule}   from '@angular/forms';
import {NgModule} from '@angular/core';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { Formdata } from './localStorage/formdata';

import { SerDATAmnvService } from './services/ser-datamnv.service';
import { GraphReports } from './Graph/graph-reports';
import { FormFlags }   from    './GlobalStatus/form-flags';
import { AllMNVcal } from './mnvCalculation/all-mnvcal';
import { NsModelCal } from './mnvCalculation/ns-model-cal';
import { App } from './services/app.service';
import { LoadingComponent } from './dashboard/mnvForms/loading/loading.component';
import { MnvReportComponent } from './dashboard/mnvForms/mnv-report/mnv-report.component';
import { BottomDailogComponent } from './bottom-dailog/bottom-dailog.component';

import { RequestResetComponent } from './dashboard/Auth/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './dashboard/Auth/password/response-reset/response-reset.component';
import { SighUpFormComponent } from './dashboard/Auth/sigh-up-form/sigh-up-form.component';
import { SignupComponent } from './dashboard/Auth/signup/signup.component';
import { LoginUserComponent } from './dashboard/Auth/login-user/login-user.component';
import { UsersignInComponent } from './dashboard/Auth/usersign-in/usersign-in.component';







@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
  
    ProjectRegistrationComponent, 
    MnvStepsComponent,
    MnvFormsComponent,
    routingComponents,
    MnvOptionComponent,
    IpDataComponent,
    ModelOutputComponent,
    SavingSummaryComponent,
    IpData2Component,
    DialogOverviewExampleDialog,
    LoadingComponent,
    MnvReportComponent,
    BottomDailogComponent,
    ShowDailogComponentbottom,
    
    RequestResetComponent,
    ResponseResetComponent,
    SighUpFormComponent,
    SignupComponent,
    LoginUserComponent,
    UsersignInComponent
    ],
    entryComponents: [DialogOverviewExampleDialog,ShowDailogComponentbottom],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSliderModule,
    BrowserAnimationsModule,
    TextFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    
    HttpClientModule,],
  
  providers: [SerDATAmnvService,GraphReports,FormFlags,AllMNVcal,NsModelCal,{
              provide:HTTP_INTERCEPTORS,
              useClass:App,
              multi:true
   }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
