import { Component, OnInit } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { NetworkService } from 'src/app/services/AuthServices/network.service';
import { TokenService } from 'src/app/services/AuthServices/token.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sigh-up-form',
  templateUrl: './sigh-up-form.component.html',
  styleUrls: ['./sigh-up-form.component.css']
})
export class SighUpFormComponent implements OnInit {

  public error:any;
  public form ={
    name : null,
    email : null,
    password : null,
    password_confirmation : null
  }

  constructor(
    private networkCall:NetworkService,
    private token:TokenService,
    private router:Router
    
    ) { 

   
  }


  ngOnInit()
   {
  }

  onSubmit(){
    // console.log(this.form);
     return this.networkCall.signup(this.form).subscribe(
       data=> this.handleResponse(data),
       error=>  this.handleError(error)
     );
     //console.log(resp);
   }
   handleResponse(data){
     console.log(data);
     this.token.set(data.access_token)
     this.router.navigateByUrl('/login')
  }
  
   handleError(error){
    this.error = error.error.errors;   
  }

}
