export class FormFlags {
    formstatus:any;
    listContent = ['project-registration','ecm-details','mnv-option','mnv-model','ip-data','ip-data1','model-output','saving-summary']
    activeList =    {'color':'#293132','background-color':'#E9F3FF' };
    deactiveList = { 'color':'black','background-color':'#ffff'};
    getformFlagStatus(){
        this.formstatus= {"projectNam":true,"ecmDetails":true,"mnvOpt":true,"mnvModel":true,"Baseline":true,"Reporting":true,"ModelOp":true,"summary":true}
        return  this.formstatus;
    }
    setformFlagStatus(data){
        let keyData = data 
        this.formstatus[keyData] = false;
      //  this.activeList.color = "#293132"
    }
    setActiveList(link){
  
        for( var i=0;i<this.listContent.length;i++){
          
            if(link==this.listContent[i] || '/mnv-forms/'+this.listContent[i] == link ){
              console.log( link) ;
              this.activeList.color = "#293132"
              this.activeList["background-color"] = "#E9F3FF"
              return   this.activeList;
            }else{
              this.deactiveList.color = "#E9F3FF"
              return   this.activeList;
            }
        }
    }  
  getMonthName(id){
    let date = id.split('-');
    let index = parseInt(date[1])

    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
         return monthNames[index-1];
  }
  
  }