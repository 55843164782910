import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayLableService {
     dataLable =  {"statnderd_equation_obtained": "Standard Equation Obtained","statnderd_equation": "Standard Equation","medel_type": "Model Type","total_baseline_occupancy": "Total Baseline Occupancy","total_baseline_cdd": "Total Baseline CDD","total_reporting_cdd": "Total Reporting CDD","total_baseline_consumption": "Total Baseline Consumption", "total_reporting_consumption": "Total Reporting Consumption","total_adj_baseline_consumption": "Total Adjusted Baseline Consumption","total_saving": "Total Saving","reporting_cdd": "Reporting Period CDD","baseline_cdd": "Baseline Period CDD","reporting_consumption": "Reporting Consumption","baseline_consumption": "Baseline Consumption", "reporting_occupancy": "Reporting Occupancy","baseline_occupancy": "Baseline Occupancy","saving": "Net Saving","adj_reporting_consumption": "Adjusted Reporting Consumption","mean": "Average","no_of_obs": "No. of Observation","relative_precission": "Relative Precision","abs_precission": "Absolute Precision","dof": "Degree of Freedom","std_err_main": "Standard error main","p_value": "P Value","t_value": "T Value","r_square_adj": "Adjusted R-Square","t_stat": "T-Stat","cvrmse": "CV-RMSE","rms": "RMS","intercept": "Intercept","coef": "Slope","r_square": "R-Square","occupancy": "Occupancy","cdd": "CDD","adj_baseline_consumption": "Adjusted Baseline Consumption","std_err": "Standard Error"};
    getArrayLable(key){
  let getKeyVal;
  getKeyVal =this.dataLable
 // console.log(getKeyVal)
  console.log(getKeyVal[key])
  return getKeyVal[key];
}
}
