import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private baseUrl = 'http://ipmvp.hetadatain.com/api';
  constructor(private http:HttpClient) { }

  login(data){
    return this.http.post(`${this.baseUrl}/login`,data);
  }

  signup(data){
    return this.http.post(`${this.baseUrl}/signup`,data);
  }

  sendPasswordresetLink(data){
    return this.http.post(`${this.baseUrl}/sendPasswordResetLink`,data);
  }

  changePassword(data){
    return this.http.post(`${this.baseUrl}/resetPassword`,data);
  }

}
