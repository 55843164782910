import { Component, OnInit } from '@angular/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder,FormArray} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../project-registration/project-registration.component';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import { Router } from '@angular/router';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';
import { DatashareService } from 'src/app/services/modal/datashare.service';

@Component({
  selector: 'app-ecm-details',
  templateUrl: './ecm-details.component.html',
  styleUrls: ['./ecm-details.component.css']
})
export class EcmDetailsComponent implements OnInit,ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  formRegisterProject:any;
  Rows:any;
  data:any;
  myForm:FormGroup;
  items: FormArray;
  orderForm: FormGroup;
  lenght:any;
  projDetails:any;
  projectName:any;
  sumPercent:Number;
  matcher = new MyErrorStateMatcher();
  constructor(public formDatas:Formdata,
    private formBuilder: FormBuilder,
    private _getData:SerDATAmnvService,
    private router: Router,private FormFlag:FormFlags,
    private dataShare:DatashareService ) { 
     
 }

  ngOnInit() { 
          /*------Active Links------*/
      console.log( this.router.url) ;   
     this.FormFlag.setActiveList(this.router.url) 
        /*------Active Links------*/

     this.formRegisterProject = this.formDatas.getLocalStoragRegProj();
     this.data = JSON.parse(this.formRegisterProject);
     this.lenght = parseInt(this.data.ecm_bill_savings)
  
     //--------------Project Details--------------//
    this.projDetails = JSON.parse(this.formDatas.getProjectReg());
    this.projectName = this.projDetails[0].project_name;
      //--------------Project Details--------------//
   
      //--------------Form Buildr INitialisaion------------------//
     this.myForm = this.formBuilder.group({
      data: this.formBuilder.array([this.createItem()])
    });
     
     if(parseInt(this.data.ecm_bill_savings)>1){
      console.log( this.data );
        let i ;
        for( i = 0;i< this.lenght-1;i++){
          this.pushItm();
        }
     }
     
   }
  createItem(): any {
     return this.formBuilder.group({
      ecm_name: [''],
      saving_percentage:[''],
      savings_type:[''],
      ecm_status:['']

    })
  }
  pushItm() {
    (this.myForm.controls['data'] as FormArray).push(this.createItem())
    console.log(this.myForm.value);
  }
  onSubmit(){
    let project_ids
    this.projDetails  = JSON.parse(this.formDatas.getProjectReg());
    this.data = this.projDetails;
    project_ids = this.data[0].id;
    this.myForm.value.project_id = project_ids;
    this.reqEcmdetails(this.myForm.value);
    console.log(this.myForm.value);
  }

 
     
  reqEcmdetails(data){
      this._getData.regEcmDtails(data).subscribe(
      res => this.getResponse(res),
      error => console.error(error));
  }
 
  getResponse(dataRes){
    this.dataShare.links[2].status = false
    this.formDatas.setLocalStoragECMdetails(JSON.stringify(this.myForm.value));
    this.router.navigateByUrl('/mnv-forms/mnv-option'); 
    console.log(dataRes);
  }
  
 
}

