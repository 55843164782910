import { Component, OnInit } from '@angular/core';
import { Formdata } from 'src/app/localStorage/formdata';
import { Validators, FormBuilder } from '@angular/forms';
import { Key } from 'protractor';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AllMNVcal } from 'src/app/mnvCalculation/all-mnvcal';
import { NsModelCal } from 'src/app/mnvCalculation/ns-model-cal';
import { Router } from '@angular/router';
import { CustomFormValidationsService } from 'src/app/services/Alerts/custom-form-validations.service';
import { DatashareService } from 'src/app/services/modal/datashare.service';
import { FormFlags } from 'src/app/GlobalStatus/form-flags';

//import { MnvCal } from 'src/app/Calculations/mnv-cal.model';
@Component({
  selector: 'app-nra',
  templateUrl: './nra.component.html',
  styleUrls: ['./nra.component.css']
})
export class NRAComponent implements OnInit {
  projDetails:any;
  displayedColumns:any;
  dataSource:any;
  dataTabl:any;
  myForm:any;
  removeComma:any;
  DataRow:any;
  timeStampLenght:any;
  calculatedCol:any;
  calculatedColdData:any;
  modelOutput:any;
  parsedModeloutput:any;
  inputData1:any;
  modelName:any;
  saveData:boolean;
  projectName:any;
  getMNVopt:any;
  getBuildata:any;
  modelType:any
  formFlag:boolean;
  constructor(private router:Router,private formDatas:Formdata,
    private formBuilder: FormBuilder, private AllMNVcals: AllMNVcal,
    private Nsmodel: NsModelCal,private validate:CustomFormValidationsService,private dataSharing:DatashareService,private parseDate:FormFlags) { }
 showHidetable = false;
  ngOnInit() {
   // this.formDatas.setNRAdata("");
    //this.formDatas.setNRAoutput("");
    this.formFlag = true;
     //--------------Project Details--------------//
     this.projDetails = JSON.parse(this.formDatas.getProjectReg());
     this.projectName = this.projDetails[0].project_name;
     
     this.getMNVopt = this.formDatas.getLocalStoragMNVopt();
    
    this.getBuildata = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
    this.modelType =  this.formDatas.getModelName(); 
       //--------------Project Details--------------// 
    this.myForm =  this.formBuilder.group({
      'nra':['', Validators.required],
     });
     
     
     // this.projDetails = this.formDatas.getNRAoutput();
      this.modelOutput = JSON.parse(this.formDatas.getModelOutput());
      this.parsedModeloutput = JSON.parse(this.modelOutput.data.data);
    
    //  this.dataTabl =  JSON.parse(this.projDetails);
      this.modelName = JSON.parse(this.formDatas.gtLocalStoragBuildMNV());
   //   this.tableData(this.dataTabl)

  }
  tableData(data){
    this.displayedColumns = ["timestamp","saving",]
    this.dataSource = data;
 }
 
  svData(data){
    this.showHidetable=true;
    this.DataRow =  this.validate.removeSpaceFromData(data.nra,'');
   if(this.DataRow){
      this.formFlag = false;
      this.addConsumtion(this.DataRow)
      if(this.modelName.model_name=="NS"){
        this.Nsmodel.getNsForData();
      }else{
        this.AllMNVcals.getBSForData();
      }
    }
   
  
  
  }
  addConsumtion(data){
    
    this.formDatas.setNRAdata(JSON.stringify(data));
    let dataArr = [];
    let SavingData = [];
      for(var key in data){
          dataArr.push(
                        {
                          "saving":parseInt( data[key] )+ this.parsedModeloutput[key].saving,
                          "timestamp":this.parseDate.getMonthName(this.parsedModeloutput[key].timestamp)
                        }
                      )

             console.log(this.parsedModeloutput[key].timestamp)         
          }

          for(var key in data){
            SavingData.push(
                          {
                            "saving":parseInt( data[key] )+ this.parsedModeloutput[key].saving,
                            "timestamp":this.parsedModeloutput[key].timestamp
                          }
                        )
  
               console.log(this.parsedModeloutput[key].timestamp)         
            }
        this.calculatedCol = ["Calculations"]
        this.calculatedColdData = dataArr;

        this.formDatas.setNRAoutput(JSON.stringify(SavingData));
        this.tableData(dataArr)
       
       

    }
    next(data){
      this.dataSharing.links[6].status = false
      this.dataSharing.links[7].status = false
      this.router.navigateByUrl('/mnv-forms/saving-summary'); 
    }
 
  
}
