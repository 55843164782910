import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectRegistrationComponent } from './dashboard/mnvForms/project-registration/project-registration.component';
import { EcmDetailsComponent } from './dashboard/mnvForms/ecm-details/ecm-details.component';
import { MnvOptionComponent } from './dashboard/mnvForms/mnv-option/mnv-option.component';
import { IpDataComponent } from './dashboard/mnvForms/ip-data/ip-data.component';
import { IpData2Component } from './dashboard/mnvForms/ip-data2/ip-data2.component';
import { ModelOutputComponent } from './dashboard/mnvForms/model-output/model-output.component';
import { SavingSummaryComponent } from './dashboard/mnvForms/saving-summary/saving-summary.component';
import { MnvModelComponent } from './dashboard/mnvForms/mnv-model/mnv-model.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MnvFormsComponent } from './dashboard/mnv-forms/mnv-forms.component';
import { NRAComponent } from './dashboard/mnvForms/nra/nra.component';
import { MnvReportComponent } from './dashboard/mnvForms/mnv-report/mnv-report.component';

import { SignupComponent } from './dashboard/Auth/signup/signup.component';
import { LoginUserComponent } from './dashboard/Auth/login-user/login-user.component';
import { SighUpFormComponent } from 'src/Auth/sigh-up-form/sigh-up-form.component';
import { UsersignInComponent } from './dashboard/Auth/usersign-in/usersign-in.component';
import { RequestResetComponent } from './dashboard/Auth/password/request-reset/request-reset.component';
import { AfterLoginService } from './services/AuthServices/after-login.service';
import { BeforeLoginService } from './services/AuthServices/before-login.service';


const routes: Routes = [
  {path:'', component:LoginUserComponent,canActivate:[BeforeLoginService]},
  {path:'dashboard', component:DashboardComponent,canActivate:[AfterLoginService]},
  { path: 'login',  component: LoginUserComponent,canActivate:[BeforeLoginService]},
  { path: 'signup', component: UsersignInComponent,canActivate:[BeforeLoginService]},  
  {path:'request-password-reset',component:RequestResetComponent,canActivate:[BeforeLoginService]},
  {path:'mnv-forms',component:MnvFormsComponent,
       children:[
          {path:'project-registration', component:ProjectRegistrationComponent,canActivate:[AfterLoginService]},
          {path:'ecm-details',component:EcmDetailsComponent,canActivate:[AfterLoginService]},
          {path:'mnv-option',component:MnvOptionComponent,canActivate:[AfterLoginService]},
          {path:'ip-data',component:IpDataComponent,canActivate:[AfterLoginService]},
          {path:'ip-data2',component:IpData2Component,canActivate:[AfterLoginService]},
          {path:'model-output',component:ModelOutputComponent,canActivate:[AfterLoginService]},
          {path:'saving-summary',component:SavingSummaryComponent,canActivate:[AfterLoginService]},
          {path:'mnv-model',component:MnvModelComponent,canActivate:[AfterLoginService]},
          {path:'*',component:MnvModelComponent,canActivate:[AfterLoginService]},
          {path:'nra',component:NRAComponent,canActivate:[AfterLoginService]},
          {path:'mnv-report',component:MnvReportComponent,canActivate:[AfterLoginService]},
      ],canActivate:[AfterLoginService]
     }
   ]
 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ProjectRegistrationComponent,EcmDetailsComponent,
                                  MnvOptionComponent,IpDataComponent,IpData2Component,
                                  ModelOutputComponent,SavingSummaryComponent,MnvModelComponent,
                                  DashboardComponent,MnvFormsComponent,NRAComponent,MnvReportComponent,
                                  LoginUserComponent,UsersignInComponent,RequestResetComponent]
