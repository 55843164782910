import { Formdata } from 'src/app/localStorage/formdata';
import { SerDATAmnvService } from 'src/app/services/ser-datamnv.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { OnInit } from '@angular/core';
export class GraphReports implements OnInit{
    chart_saving:any;
    chart_consumption:any ;


    ngOnInit(){
      console.log("Method not implemented.");
    }
     consumptionDatas(consumptionsData,id,nameArr,lable) {
         var chart = am4core.create(id, am4charts.XYChart);
          chart.data = consumptionsData;
          var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.dateFormatter.dateFormat = "dd-mm-yyyy";
         
          dateAxis.fontSize = 10;
          chart.legend = new am4charts.Legend();
          chart.legend.fontSize =12
          chart.exporting.menu = new am4core.ExportMenu();
          var markerTemplate = chart.legend.markers.template;
          markerTemplate.width = 14;
          markerTemplate.height = 14;
          
          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.fontSize = 10;
          valueAxis.title.text = "kWh";
         
          let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis2.fontSize = 10;
          valueAxis2.renderer.opposite = true;
          valueAxis2.renderer.minGridDistance = 0.5;
          
          let series = chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = lable;
          series.dataFields.dateX = "timestamp";
          series.dateFormatter.dateFormat = "yyyy-MM-dd";
          series.name = nameArr[0];
          series.fontSize = 10;
         // series.legendSettings.labelText = "Import kWh"
          series.yAxis = valueAxis;
          series.tooltipText = "{name}:{valueY.value}"
          series.strokeWidth = 2;
          series.fill = series.stroke;
          series.tensionX = 0.77;
          series.fillOpacity = 0.3  
          series.fill = am4core.color("blue")
          series.stroke = am4core.color("blue")

        let series1 = chart.series.push(new am4charts.LineSeries());
          series1.dataFields.valueY = "reporting_consumption";
          series1.dataFields.dateX = "timestamp";
         
          series1.dateFormatter.dateFormat = "yyyy-MM-dd";
          series1.name = nameArr[1];
          series1.fontSize = 10;
          //series1.legendSettings.labelText = "Import kWh"
          series1.yAxis = valueAxis;
        
          series1.tooltipText = "{name}:{valueY.value}"
          series1.strokeWidth = 2;
          series1.fill = series1.stroke;
          series1.tensionX = 0.77;
          series1.fillOpacity = 0.3  
          series1.fill = am4core.color("green")
          series1.stroke = am4core.color("green")

          let series2 = chart.series.push(new am4charts.LineSeries());
          series2.dataFields.valueY = "baseline_consumption";
          series2.dataFields.dateX = "timestamp";
         
          series2.dateFormatter.dateFormat = "yyyy-MM-dd";
          series2.name = nameArr[2];
          series2.fontSize = 10;
          //series1.legendSettings.labelText = "Import kWh"
          series2.yAxis = valueAxis;
        
          series2.tooltipText = "{name}:{valueY.value}"
          series2.strokeWidth = 2;
          series2.fill = series1.stroke;
          series2.tensionX = 0.77;
          series2.fillOpacity = 0.3  
          series2.fill = am4core.color("red")
          series2.stroke = am4core.color("red")
          chart.cursor = new am4charts.XYCursor();

        

    } 

    twoDatalineGraph(consumptionsData,id) {
        var chart = am4core.create(id, am4charts.XYChart);
        chart.data = consumptionsData;
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dateFormatter.dateFormat = "dd-mm-yyyy";
        
        dateAxis.fontSize = 10;
        chart.legend = new am4charts.Legend();
        chart.legend.fontSize =12
        chart.exporting.menu = new am4core.ExportMenu();
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 14;
        markerTemplate.height = 14;
        
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.fontSize = 10;
        valueAxis.title.text = "kWh";
        
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.fontSize = 10;
        valueAxis2.renderer.opposite = true;
        valueAxis2.renderer.minGridDistance = 0.5;
        
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "TempGrosVals";
        series.dataFields.dateX = "Timestamp";
        series.name = "Total Gross Saving(kWh)"
        series.dateFormatter.dateFormat = "yyyy-MM-dd";
      
        series.fontSize = 10;
        // series.legendSettings.labelText = "Import kWh"
        series.yAxis = valueAxis;
        
        series.tooltipText = "{name}:{valueY.value}"
        series.strokeWidth = 2;
        series.fill = series.stroke;
        series.fillOpacity = 0.3  
        series.fill = am4core.color("green")
        series.stroke = am4core.color("green")
        let series1 = chart.series.push(new am4charts.ColumnSeries());
        
        series1.dataFields.valueY = "TemPerSavings";
        series1.dataFields.dateX = "Timestamp";
        series1.name = "Total Net Saving(kWh)"
        series1.dateFormatter.dateFormat = "yyyy-MM-dd";
      
        series1.fontSize = 10;
        
        series1.yAxis = valueAxis;
  
        series1.tooltipText = "{name}:{valueY.value}"
        series1.strokeWidth = 2;
        series1.fill = series.stroke;
        series1.fillOpacity = 0.3  
        series1.fill = am4core.color("red")
        series1.stroke = am4core.color("red")

      
        chart.cursor = new am4charts.XYCursor();

        

    } 
    savingDatas(savingsData,id) {
         var chart = am4core.create(id, am4charts.XYChart);
          chart.data = savingsData;
          var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.dateFormatter.dateFormat = "dd-mm-yyyy";
         
          dateAxis.fontSize = 10;
          chart.legend = new am4charts.Legend();
          chart.legend.fontSize =12
          chart.exporting.menu = new am4core.ExportMenu();
          var markerTemplate = chart.legend.markers.template;
          markerTemplate.width = 14;
          markerTemplate.height = 14;
          
          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.fontSize = 10;
          valueAxis.title.text = "kWh";
         
          let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis2.fontSize = 10;
          valueAxis2.renderer.opposite = true;
          valueAxis2.renderer.minGridDistance = 0.5;
          let series = chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = "saving";
          series.dataFields.dateX = "timestamp";
         series.name = "Net Saving"
          series.dateFormatter.dateFormat = "yyyy-MM-dd";
          series.name = "date";

        //  series.columns.template.fillOpacity = .8;
          series.fontSize = 10;
        //  series.legendSettings.labelText = "Import kWh"
         
        series.yAxis = valueAxis;
        series.name = "Net Saving";
        series.tooltipText = "{name}:{valueY.value}"
        series.strokeWidth = 2;
        
        series.fill = series.stroke;
        series.tensionX = 0.77;
        series.fillOpacity = 0.3  
        chart.cursor = new am4charts.XYCursor();


      }


}
