import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/AuthServices/network.service';
import { TokenService } from 'src/app/services/AuthServices/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/AuthServices/auth.service';
import { FormGroup,FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
@Component({
  selector: 'app-usersign-in',
  templateUrl: './usersign-in.component.html',
  styleUrls: ['./usersign-in.component.css']
})
export class UsersignInComponent implements OnInit , ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  } 
  myForm:FormGroup;
  constructor(
    private networkCall:NetworkService,
    private token:TokenService,
    private router:Router,
    private formBuilder: FormBuilder,
    private auth:AuthService
    ) { 

   
  }
  public error = null ;
  public form ={
    names : null,
    email : null,
    password : null,
    password_confirmation : null
  }



  ngOnInit()
   {
    this.myForm =  this.formBuilder.group({
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'user_name': [null, Validators.required],
      'organization_name': [null, Validators.required],
      'password': [null, Validators.required],
      'password_confirmation': [null, Validators.required],
  })
}

  onSubmit(data){
    // console.log(this.form);
     return this.networkCall.signup(data).subscribe(
       data=> this.handleResponse(data),
       error=>  this.handleError(error)
     );
     //console.log(resp);
   }
   handleResponse(data){
     this.token.set(data.access_token)
     this.auth.changeAuthStatus(true);
    this.router.navigateByUrl('/dashboard')
  }
  
   handleError(error){
    this.error = error.error.errors;   
  }

}