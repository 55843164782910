import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})

export class GetAllAlertsService {
  Swal:any;
   constructor() { }
 public  showAlert(data){
    //console.log(data)
     this.Swal = require('sweetalert2')
    Swal.fire({
      title: 'Error!',
      text: data,
      icon: 'error',
      confirmButtonText: 'OK!'
    })
   }

   public  showAlertSuccess(data){
    //console.log(data)
     this.Swal = require('sweetalert2')
    Swal.fire({
      title: 'success',
      text: data,
      icon: 'success',
      confirmButtonText: 'OK!'
    })
   }

   public checkResponse(response){
     let data = {};
     console.log(response.Exception.lenght)
     if(Object.keys(response).length==0){
      this.showAlert("Network Error! Please Try Again")
      return false
       }  else{
         return true
       }
   }
}
